import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/home/home.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about/index.vue'),
      },
      {
        path: '/service',
        name: 'service',
        component: () => import('@/views/service/service.vue'),
      },
      {
        path: '/industry',
        name: 'industry',
        component: () => import('@/views/industry/industry.vue'),
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/contact/contact.vue'),
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
